<template>
	<div class="contents fileMenu">
		<addSameFold :state="state" :fatherId="fatherId" :subFatherId="subFatherId" :id="operateId" :level="currentLevel"
			v-if="addSameFoldLoad" :hide="hide"></addSameFold>
		<rename :state="state" :fatherId="fatherId" :subFatherId="subFatherId" :id="operateId" :level="currentLevel"
			v-if="renameLoad" :hide="hide"></rename>
		<div class="left">
			<div :class="cloudKey == 'own' ? 'menus menuActived' : 'menus'" @click="cloudKey = 'own'">
				<a-tooltip placement="right" title="个人云盘">
					<img v-if="cloudKey == 'group'" src="../../assets/people.png">
					<img v-if="cloudKey == 'own'" src="../../assets/own.png">
				</a-tooltip>
			</div>
			<div :class="cloudKey == 'group' ? 'menus menuActived' : 'menus'" @click="cloudKey = 'group'">
				<a-tooltip placement="right" title="项目组云盘">
					<img v-if="cloudKey == 'own'" src="../../assets/muls.png">
					<img v-if="cloudKey == 'group'" src="../../assets/group.png">
				</a-tooltip>
			</div>
		</div>
		<div class="right" style="overflow-x: hidden;">
			<div @click="addfolder('init')" v-if="cloudKey == 'own' && treeData.length == 0"
				style="cursor: pointer; color: black;display: flex;justify-content: center;align-items: center;height: 100%;font-size: 16px;font-weight: 500;position: absolute;left: 0;right: 0;">
				<a-icon style="margin-right: 5px;" type="file-add" />
				创建文件夹
			</div>
			<div v-if="cloudKey == 'group' && groupData.length == 0"
				style=" color: black;display: flex;justify-content: center;align-items: center;height: 100%;font-size: 16px;font-weight: 500;">
				<a-icon style="margin-right: 5px;" type="info-circle" />
				暂未加入项目组
			</div>
			<a-tree :blockNode="true" :showIcon="true" :selected-keys="[currentId]" checkStrictly @select="select"
				:replaceFields="replaceFields" v-if="cloudKey == 'own'"
				style=" width:calc(100% - 32px ) ; margin-right: 27px;  text-align: left; " :tree-data="treeData">
				<template slot="folder" slot-scope="item1">
					<a-icon v-if="item1.expanded || item1.selected" type="folder-open" theme="filled" :class="[ item1.selected ? 'active-icon' : 'inactive-icon' ]" />
					<a-icon v-else type="folder" theme="filled" class="inactive-icon"/>

				</template>
				<template #title="{ levelId, levelName }">
					<a-tooltip placement="rightBottom" :title="levelName">
						<div style="width: 100%; display: inline-flex; align-items: center;">
							<span :title="levelName"
								style="flex: 1; width: 0;text-align: left; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{
									levelName }}</span>
						

							<a-dropdown :trigger="['click']">
								<a-icon type="menu" @click.stop="select(levelId, 'operate')"
									style="margin-left: 6px;" />

								<template #overlay>
									<a-menu style="display: flex;align-items: center;flex-direction: column;">
										<a-menu-item style="width: 100%;" key="1" @click="addfolder('same', levelId)">
											新建同级文件夹
										</a-menu-item>
										<a-menu-item style="width: 100%;" v-if="currentLevel != 3"
											@click="addfolder('next', levelId)">新建子文件夹</a-menu-item>
										<a-menu-item style="width: 100%;" @click="rename(levelId)">
											重命名
										</a-menu-item>
										<a-menu-item style="width: 100%;" @click="deletes(levelId)">
											删除
										</a-menu-item>
									</a-menu>
								</template>
							</a-dropdown>
						</div>
					</a-tooltip>
				</template>
			</a-tree>
			<!-- <a-directory-tree :blockNode="true"  :selected-keys="[currentId]" checkStrictly @select="select" :replaceFields="replaceFields"
				v-if="cloudKey == 'own'" style="float: left; width: 100%; margin-top: 15px; text-align: left; " :tree-data="treeData">
				<a-icon type="folder" slot="folder"  />
			
				<template slot="switch" slot-scope="item1">
					{{  test(item1)  }}
					<div  v-if="item1.lst.length === 0">
						<a-icon type="minus" />
					</div>
					<a-icon v-else-if="!item1.expanded" type="plus-square" />
					<a-icon v-else type="minus-square" />
				</template>
				<template #title="{ levelId, levelName }">
					<div>
					<a-tooltip placement="rightBottom" :title="levelName">
						<span :title="levelName"
							style=" width: 100%;text-align: left; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: inline-block;">{{
								levelName }}</span>
					</a-tooltip>

					<a-dropdown :trigger="['click']">
						<a-icon type="menu" @click.stop="select(levelId, 'operate')"
							style="position: absolute;right: 16px;margin-top: 5px;" />

						<template #overlay>
							<a-menu style="display: flex;align-items: center;flex-direction: column;">
								<a-menu-item style="width: 100%;" key="1" @click="addfolder('same', levelId)">
									新建同级文件夹
								</a-menu-item>
								<a-menu-item style="width: 100%;" v-if="currentLevel != 3" @click="addfolder('next', levelId)">新建子文件夹</a-menu-item>
								<a-menu-item style="width: 100%;" @click="rename(levelId)">
									重命名
								</a-menu-item>
								<a-menu-item style="width: 100%;" @click="deletes(levelId)">
									删除
								</a-menu-item>
							</a-menu>
						</template>
					</a-dropdown>
				</div>
				</template>
			</a-directory-tree> -->
			<div v-if="cloudKey == 'group'" :style="index == 0 ? 'margin-top: 16px;' : ''"
				v-for="(item, index) in groupData" :key="item.projectId">
				<a-tooltip placement="right" :title="item.projectName">
					<div :class="projectId == item.projectId ? 'rightItem menuActived' : 'rightItem'"
						@click="selectProject(item)">
						<div style="width: 24px;height: 24px;background: #00C247;margin: 0 10px;">
							<img src="../../assets/temp.png" />
						</div>

						<div :title="item.projectName"
							style=" text-align: left;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: inline-block;">
							{{ item.projectName }}</div>
					</div>
				</a-tooltip>

			</div>
		</div>
	</div>
</template>

<script>
import {
	returnfolderpath,
	deletefoldername,
	returnmyproject,
} from '../../api/anotherIndex'
import addSameFold from '../disk/addSameFold.vue'
import rename from '../disk/rename.vue'
export default {
	components: {
		addSameFold,
		rename
	},
	watch: {
		cloudKey: async function (newData, oldData) {
			//newData是更新后的数据
			//oldData是旧数据
			//console.log(newData, oldData)
			this.$store.commit('updateSpin', true)
			this.currentId = 0;
			this.projectId = 0;
			if (newData == 'own') {
				//this.currentId = 0
				await this.returnfolderpath()
				if (this.currentId == 0) {
					this.currentId = this.treeData.length > 0 ? this.treeData[0].levelId : 0
					//this.$emit('select', this.currentId)
					this.$router.replace({
						//path: `/cloudDisk/own?t=${(new Date()).getTime()}`,
						name: 'own',
						query: {
							t: (new Date()).getTime(),
							folderId: this.currentId
						}
					})
				}
			} else if (newData == 'group') {
				//this.projectId = 0
				await this.returnmyproject()
				this.$router.replace({
					//path: `/cloudDisk/group?t=${(new Date()).getTime()}`
					name: 'group',
					query: {
						t: (new Date()).getTime(),
						folderId: this.projectId
					}
				})
			}
		}
	},
	data() {
		return {
			cloudKey: '',
			operateId: 0,
			treeData: [],
			groupData: [],
			visible: false,
			hide: false,
			state: '',
			addSameFoldLoad: false,
			renameLoad: false,
			currentId: 0,
			fatherId: 0,
			subFatherId: 0,
			currentLevel: 0,
			projectId: 0,
			replaceFields: {
				children: 'lst',
				title: 'levelName',
				key: 'levelId',
			},
			// expandedKeys: ['0-0-0', '0-0-1'],

		}
	},
	methods: {
		deletes(data) {
			var that = this
			this.$confirm({
				title: '确认是否删除文件夹?',
				content: '请谨慎删除，文件夹删除后里面文件将会一起删除！',
				onOk() {
					//   return new Promise((resolve, reject) => {
					//     setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
					//   }).catch(() => console.log('Oops errors!'));
					that.deletefoldername(data)
				},
				onCancel() { },
			});
		},
		onContextMenuClick(treeKey, menuKey) {
			console.log(menuKey)
		},
		async returnfolderpath(state) {
			const res = await returnfolderpath()
			if (res.success) {
				let dutyTypeJson = JSON.stringify(res.data)
				let dutyTypes = JSON.parse(dutyTypeJson.replace(/seconId/g, "levelId")
					.replace(/seconName/g, "levelName").replace(/threeLevelId/g, "levelId").replace(
						/threeLevelName/g, "levelName")
					.replace(/levelFolderlst/g, "lst")
					.replace(/seconFolderlst/g, "lst")
				)
				this.treeData = dutyTypes
				const that = this;
				this.treeData = this.treeData.map(item => {
					return that.goDeep(item) /*  {
						...item,
						//slots: { icon: 'folder' } ,
						scopedSlots: {
							switcherIcon: 'switch',
							title: 'title',
							icon: 'folder'
						}
						//isLeaf: false,
					} */
				})
				//console.log(this.treeData)
				if (this.currentId == 0) {
					this.currentId = this.treeData[0].levelId
					/* this.$emit('select', this.currentId)
					console.log(this.currentId) */
					this.$router.replace({
						//path: `/cloudDisk/own?t=${(new Date()).getTime()}`,
						name: 'own',
						query: {
							t: (new Date()).getTime(),
							folderId: this.currentId
						}
					})
				}
				if (state) {
					//console.log(this.treeData)
					this.currentId = this.treeData && this.treeData.length > 0 ? this.currentId : 0
					/* this.$emit('select', this.currentId) */
					this.$router.replace({
						//path: `/cloudDisk/own?t=${(new Date()).getTime()}`,
						name: 'own',
						query: {
							t: (new Date()).getTime(),
							folderId: this.currentId
						}
					})
				}
			}
		},
		goDeep(item) {
			const that = this;
			item && item.lst && (item.lst = item.lst.map(dir => {
				return that.goDeep(dir)
				/* return {
					...dir
				} */
			}))
			return {
				...item,
				scopedSlots: {
					switcherIcon: 'switch',
					title: 'title',
					icon: 'folder'
				},
				class: 'folder-tree-node'
			};
		},
		async returnmyproject() {
			const res = await returnmyproject()
			//console.log(res)
			if (res.success) {
				this.groupData = res.data
				if (this.projectId == 0) {
					this.projectId = this.groupData.length > 0 ? this.groupData[0].projectId : 0
					//this.$emit('select', this.projectId)
				}
			}

			//console.log(res)
		},
		select(key, state) {
			/* console.log(state)
			state.node && state.node.onExpand(); */
			if (key.length == 0) {
				key = this.currentId
			}
			let index = 0
			for (var item of this.treeData) {
				if (item.levelId == key) {
					index = 1
					this.fatherId = item.levelId

					break
				}
				if (item.lst && item.lst.length > 0) {
					for (var ite of item.lst) {
						if (ite.levelId == key) {
							index = 2
							this.fatherId = item.levelId
							this.subFatherId = ite.levelId
							//console.log(item)
							break
						}
						if (ite.lst && ite.lst.length > 0) {
							for (var it of ite.lst) {
								if (it.levelId == key) {
									index = 3
									// this.subFatherId = ite.levelId
									this.fatherId = item.levelId
									this.subFatherId = ite.levelId
									//console.log(ite)
									break
								}
							}
						}
					}
				}
			}
			if (key[0]) {
				this.currentId = key[0]
				this.currentLevel = index
			}
			else {
				// this.currentId = key
				this.currentLevel = index
			}
			/* console.log(this.currentId)
			console.log(this.currentLevel) */
			if (state !== 'operate') {
				//this.$emit('select', key)
				this.$router.replace({
					//path: `/cloudDisk/own?t=${(new Date()).getTime()}`,
					name: 'own',
					query: {
						t: (new Date()).getTime(),
						folderId: key
					}
				})
			}



		},
		async deletefoldername(key) {
			//let data = "?FolderId=" + key
			const res = await deletefoldername({
				FolderId: key
			})
			if (res.success) {
				this.returnfolderpath('delete')
				this.$message.success('删除成功')
			}
		},

		addfolder(state, key) {
			this.addSameFoldLoad = false
			setTimeout(() => {
				this.operateId = key
				this.addSameFoldLoad = true
				this.hide = true
				this.state = state
			}, 100)
		},
		rename(key) {
			this.renameLoad = false
			setTimeout(() => {
				this.operateId = key
				this.renameLoad = true
				this.hide = true

			}, 100)
		},
		selectProject(data) {
			if (data.projectId != this.projectId) {
				this.projectId = data.projectId
				//this.$emit('select', this.projectId)
				this.$router.replace({
					//path: `/cloudDisk/group?t=${(new Date()).getTime()}`
					name: 'group',
					query: {
						t: (new Date()).getTime(),
						folderId: this.projectId
					}
				})
			}
		},
		test(item) {
			console.log(item)
		}
	},
	created() {
		this.cloudKey = 'own'
	}
}
</script>

<style scoped>
.contents {
	width: 272px;
	height: 824px;
	background-color: white;
	display: flex;
	margin-bottom: 32px;
	/* margin-top: 6px; */
	z-index: 100 !important;
}

.left {
	width: 48px;
	z-index: 15;
	border-right: 1px solid #EBEBEB;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.menus:hover {
	opacity: 0.8 !important;
}

.menus {
	width: 32px;
	height: 32px;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.menuActived {
	background: #F0F5FF !important;
}

.right {
	position: relative;
	max-height: 824px;
	overflow-y: scroll;
	width: 224px;
}

.rightItem {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	/* margin: 2px 0px 0 10px; */
	height: 40px;
	cursor: pointer;
	transition: all .3s;
	border-radius: 2px;
	width: 100%;
	margin-top: 4px;
	padding-right: 10px;
}

.rightItem:hover {
	background: #EEEEEE;
}
</style>
<!-- <style>
.fileMenu .ant-tree-switcher-icon {
	color: rgba(0, 0, 0, 0.45) !important;
}

.fileMenu .ant-tree-title {
	font-size: 14px !important;
	color: rgba(0, 0, 0, 0.85) !important;
	font-weight: 550 !important;
}
</style> -->

<style lang="less" scoped>
.fileMenu {
	box-shadow: @srims-primary-box-shadow;
	/deep/ .folder-tree-node {
		.ant-tree-switcher {
			position: relative;
			z-index: 200;
		}
		.ant-tree-node-content-wrapper {
			.ant-tree-iconEle {
				position: relative;
				z-index: 200;
			}
			.ant-tree-title {
				/* position: relative;
				width: calc(100% - 27px);
				z-index: 200; */
				span {
					position: relative;
					z-index: 200;
				}
			}
			.anticon {
				z-index: 200;
			}
		}
	}

	/deep/ .folder-tree-node:not(.ant-tree-treenode-selected) {
		.ant-tree-node-content-wrapper:not(.ant-tree-treenode-selected) {
			
			&:hover {
				background: transparent;

				&::before {
					content: '';
					position: absolute;
					background: #bae7ff;
					//background: #1890ff;
					right: 0;
					left: 0;
					height: 24px;
					transition: all .3s;
				}
			}
		}


	}


	/deep/ .ant-tree-node-selected {
		background: transparent !important;
	}

	/* /deep/ .ant-tree-node-content-wrapper {

	} */
	/* /deep/ .folder-tree-node:not(.ant-tree-node-selected) {
		position: relative;
		background-size: 100% 27px;
		
		&:hover {
			background: #bae7ff;
		}

	} */

	/deep/ .ant-tree-treenode-selected {
		
		z-index: 100;

		&>.ant-tree-node-content-wrapper,
		&>.ant-tree-switcher {
			color: #fff;
		}

		&::before {
			content: '';
			position: absolute;
			background: @srims-primary-color;
			//background: #1890ff;
			right: 0;
			left: 0;
			height: 24px;
			transition: all .3s;
		}

	}

	.active-icon {
		color: inherit;
	}

	.inactive-icon {
		color: @srims-primary-color;
	}
}
</style>