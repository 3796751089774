<template>
	<div style="display: flex;">
		<fileMenu ref="fileMenu" @createdFold="initFold" @select="select" style="z-index: 600;"></fileMenu>
		<div class="content">
			<router-view :key="foldId" :foldId="foldId" ></router-view>
		</div>
	</div>
</template>

<script>
	import fileMenu from '../../components/common/fileMenu.vue'
	export default {
		components: {
			fileMenu
		},
		data(){
			return{
				foldId: this.$route.query && this.$route.query.folderId ? this.$route.query.folderId : 0,
				cloudDiskLoad:true,
				timer: null,
			}
		},
		methods:{
			select(key, mode){
				// if(key==0){
				// 	return
				// }
				//this.cloudDiskLoad = false
				this.foldId = key
				/* setTimeout(()=>{
					this.cloudDiskLoad = true
				},10) */
			},
			initFold(){
				this.$refs.fileMenu.addfolder('init')
			}
		},
		watch: {
			'$route': {
				handler(e) {
					if(e.query&& e.query.folderId) {
						this.foldId = typeof e.query.folderId == 'object' ? e.query.folderId[0] : e.query.folderId
						//console.log(this.foldId)
					}
					else {
						this.foldId = 0
					}
				},
				deep:true,
				immediate: true
			}
		}
	}
</script>

<style lang="less" scoped>
	.content {
		width: calc(100% - 308px);
		min-height: 500px;
		background: #FFFFFF;
		margin: 0 auto;
		margin-top: 16px;
		margin-bottom: 30px;
		box-shadow: @srims-primary-box-shadow;
		border-radius: 4px;
	}
</style>
