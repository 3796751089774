<template>
	<div>
		<lightBox @submit="submit" :hide="hide" :title="state == 'same' ? '新增同级文件夹' : state == 'next' ? '新增子文件夹' : '创建文件夹'"
			class="lightBox">
			<template scope="data">
				<a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
					<a-form-model-item label="名称" has-feedback prop="foldName">
						<a-input :maxLength="15" v-model="ruleForm.foldName" placeholder="请输入文件夹名称" autocomplete="off">
						</a-input>
					</a-form-model-item>
				</a-form-model>
			</template>
		</lightBox>
	</div>
</template>

<script>
import lightBox from '../lightBox.vue'
import {
	addfolder
} from '../../api/anotherIndex'
export default {
	props: ['hide', 'id', 'level', 'fatherId', 'subFatherId', 'state'],
	data() {
		return {
			ruleForm: {
				foldName: '',

			},
			layout: {
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 13
				},
			},
			rules: {
				foldName: [{
					required: true,
					message: '文件夹名称不能为空',
					trigger: 'blur'
				}],

			},
		}
	},
	components: {
		lightBox
	},
	created() {

	},
	methods: {
		submit() {

			this.$refs.ruleForm.validate(async valid => {
				if (valid) {
					//const foldName = encodeURIComponent(this.ruleForm.foldName)
					const foldName = this.ruleForm.foldName;
					const currentLeve = this.level

					if (this.state == 'init') {
						//let datad = '?FolderName=' + foldName + '&FatherFolderId=0'
						this.addfolder({
							FolderName: foldName,
							FatherFolderId: 0
						})
					}

					switch (currentLeve) {
						case 1:
							let data
							if (this.state == 'same') {
								//data = '?FolderName=' + foldName + '&FatherFolderId=0'
								data = {
									FolderName: foldName,
									FatherFolderId: 0
								}
							}
							if (this.state == 'next') {
								//data = '?FolderName=' + foldName + '&FatherFolderId=' + this.id
								data = {
									FolderName: foldName,
									FatherFolderId: this.id
								}
							}
							this.addfolder(data)
							break;
						case 2:
							let data2
							if (this.state == 'same') {
								//data2 = '?FolderName=' + foldName + '&FatherFolderId=' + this.fatherId
								data2 = {
									FolderName: foldName,
									FatherFolderId: this.fatherId
								}
							}
							if (this.state == 'next') {
								//data2 = '?FolderName=' + foldName + '&FatherFolderId=' + this.id
								data2 = {
									FolderName: foldName,
									FatherFolderId: this.id
								}
							}
							this.addfolder(data2)
							//console.log('2层')
							break;
						case 3:
							//let data3 = '?FolderName=' + foldName + '&FatherFolderId=' + this.subFatherId
							data3 = {
								FolderName: foldName,
								FatherFolderId: this.subFatherId
							}
							this.addfolder(data3)
							//console.log('3层')
							break;
					}
				} else {
					console.log('error submit!!');
					return
				}
			});
		},
		async addfolder(data) {
			if (!data) {
				return
			}
			const res = await addfolder(data)
			if (res.success) {
				this.$parent.returnfolderpath()
				this.$message.success('新增成功')
				this.$parent.addSameFoldLoad = false
			} 
		}
	}
}
</script>


