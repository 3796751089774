<template>
	<div>
		<lightBox @submit="submit" :hide="hide" title="重命名文件夹" class="lightBox">
			<template scope="data">
				<a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
					<a-form-model-item label="名称" has-feedback prop="foldName">
						<a-input v-model="ruleForm.foldName" placeholder="请输入文件夹名称" autocomplete="off">
						</a-input>
					</a-form-model-item>
				</a-form-model>
			</template>
		</lightBox>
	</div>
</template>

<script>
	import lightBox from '../lightBox.vue'
	import {
		editfoldername
	} from '../../api/anotherIndex'
	export default {
		props: ['hide', 'id'],
		data() {
			return {
				ruleForm: {
					foldName: '',

				},
				layout: {
					labelCol: {
						span: 4
					},
					wrapperCol: {
						span: 13
					},
				},
				rules: {
					foldName: [{
						required: true,
						message: '文件夹名称不能为空',
						trigger: 'blur'
					}],

				},
			}
		},
		components: {
			lightBox
		},
		created() {
			this.ruleForm.foldName = ''
		},
		methods: {
			submit() {
				this.$refs.ruleForm.validate(async valid => {
					if (valid) {
						this.editfoldername()
					} else {
						console.log('error submit!!');
						return
					}
				});
			},
			async editfoldername() {
				const res = await editfoldername({
					Name: this.ruleForm.foldName,
					FolderId: this.id
				})
				if (res.success) {
					this.$parent.returnfolderpath()
					this.$message.success('重命名成功')
					this.$parent.hide = false
					this.$parent.renameLoad = false
				}
			}
		}
	}
</script>

