<template>
	<!-- <div v-if="hides">
		<div
			style="z-index: 100; background-color: rgba(0, 0, 0, 0.65);position: fixed;top: 0;width: 100%;height: 100%;left: 0;right: 0;">
		</div>
		<div class="container">

			<div class="temp">
				<div style="padding:0px 0pt 0pt 16px;font-weight: 550;">{{title}}</div>
				<img @click="hides=false" style="cursor: pointer; transform: scale(1);padding: 0px 16px 0pt 0px;"
					src="../assets/x.png">
			</div>
			<div class="content">
				<slot></slot>
			</div>
			<div style="display: flex;justify-content: center;align-self: center;margin-bottom: 24px;">
				<a-button @click="hides=false"
					style="width: 88px;height: 32px;border-radius: 2px;border: 0 !important;background: #EEEEEE;color: rgba(0, 0, 0, 0.65);margin-right: 24px;">
					取消
				</a-button>
				<a-button type="primary" @click="submit"
					style="width: 88px;height: 32px;border-radius: 2px;border: 0 !important;color: #FFFFFF;">
					确定
				</a-button>
			</div>
		</div>

	</div> -->
	<a-modal :visible="hides" :title="title" :width="600" @cancel="hides = false">
		<div class="content">
				<slot></slot>
		</div>
		<template slot="footer">
			<div style="text-align: center;">
				<a-button @click="hides=false"
					style="width: 88px;height: 32px;border-radius: 2px;border: 0 !important;background: #EEEEEE;color: rgba(0, 0, 0, 0.65);margin-right: 24px;">
					取消
				</a-button>
				<a-button type="primary" @click="submit"
					style="width: 88px;height: 32px;border-radius: 2px;border: 0 !important;color: #FFFFFF;">
					确定
				</a-button>
			</div>
		</template>
	</a-modal>

</template>

<script>
	export default {
		props: ['hide', 'title'],
		data() {
			return {
				hides:this.hide
			}
		},
		created() {

		},
		methods: {
			submit(){
				this.$emit('submit')
			}
		}
	}
</script>

<style scoped>
	.container {
		width: 600px;
		min-height: 50px;
		border-radius: 2px;
		background: #FFFFFF;
		position: fixed;
		top: 240px;
		z-index: 500;
		left: 50%;
	/* 	top: 58%; */
	margin-left: -300px;

	}

	.temp {
		width: 600px;
		height: 40px;
		border-radius: 2px 2px 0px 0px;
		background: #EEEEEE;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.85);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.content {
		/* width: 400px;
		margin: 0px auto;
		display: flex;
		min-height: 192px;
		justify-content: space-around;
		flex-direction: column;
		align-items: flex-start;
 */

		
		margin-left: 86px;
	}

	.msg {
		width: 400px;
		margin: 0 auto;
		justify-content: space-around;
		flex-direction: column;
		align-items: flex-start;
		min-height: 50px;
		margin-top: 32px;
		margin-bottom: 24px;


	}
</style>
